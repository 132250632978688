import { config as fontAwesomeConfig } from "@fortawesome/fontawesome-svg-core";
import {
  Links,
  type LinksFunction,
  type LoaderFunctionArgs,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  type ShouldRevalidateFunction,
  useRouteError,
} from "react-router";
fontAwesomeConfig.autoAddCss = false;

import "@flpstudio/design-system/global.css";
import "@fortawesome/fontawesome-svg-core/styles.css";

import { FullPageError } from "@/components/organisms/Error/FullPageError";
import { ThemeProvider, theme } from "@/styles/theming";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { fetchFeatureFlags } from "./third-party/AmplitudeExperiment";

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="manifest" href="/manifest.webmanifest" />
        <link rel="icon" href="/favicon.ico" sizes="32x32" />
        <link rel="icon" type="image/svg+xml" href="/favicon/favicon.svg" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/favicon.png"
        />
        <meta property="og:site_name" content="GuideStack" />
        <meta property="og:image" content="/favicon/favicon.png" />
        <meta property="og:locale" content="en_US" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export async function loader({ request }: LoaderFunctionArgs) {
  const cookieString = request.headers.get("cookie") || "";
  const featureFlags = await fetchFeatureFlags(cookieString);

  return { featureFlags };
}

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Outlet />
    </ThemeProvider>
  );
}

export const links: LinksFunction = () => [
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap",
  },
];

export const shouldRevalidate: ShouldRevalidateFunction = () => {
  // stop revalidating after the initial load since the evaluation of feature flags
  // just once on initial load is sufficient for our use case
  return false;
};

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <FullPageError />;
}
